import * as tslib_1 from "tslib";
import { CompanyDetailPageComponent } from '@agent-ds/company/pages';
import { JobDetailPageComponent } from '@agent-ds/jobs/pages/job-detail-page/job-detail-page.component';
import { PopupControlComponent } from '@agent-ds/shared/components/popup-control/popup-control.component';
import { getProgressStatusLabel } from '@agent-ds/shared/constants';
import { EnterpriseDepartmentUserType, } from '@agent-ds/shared/interfaces';
import { DetailPage, RefreshEvent } from '@agent-ds/shared/models/detail-page';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { CompanyApiService, DynamicFieldService, JobApiService, MasterApiService, ProgressApiService, StudentApiService, UserApiService, } from '@agent-ds/shared/services';
import { StudentDetailPageComponent } from '@agent-ds/student/pages';
import { OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
var DEFAULT_TRUNCATE_AT = 300;
var ProgressDetailComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ProgressDetailComponent, _super);
    function ProgressDetailComponent(progressApiService, dynamicService, masterApiService, userApiService, studentApiService, companyApiService, jobApiService, activeRoute, router, datePipe) {
        var _this = _super.call(this, router, progressApiService, activeRoute) || this;
        _this.progressApiService = progressApiService;
        _this.dynamicService = dynamicService;
        _this.masterApiService = masterApiService;
        _this.userApiService = userApiService;
        _this.studentApiService = studentApiService;
        _this.companyApiService = companyApiService;
        _this.jobApiService = jobApiService;
        _this.activeRoute = activeRoute;
        _this.router = router;
        _this.datePipe = datePipe;
        _this.sideActions = [{ phases: ['700px', '100%'] }];
        _this.tabs = {};
        _this.urlTag = 'progresses';
        _this.progress = null;
        _this.getStatusLabel = getProgressStatusLabel;
        _this.isStopReasonEditInProgress = false;
        _this.isHistoryRemarkEditInProgress = false;
        _this.isTurnOverFormEditInProgress = false;
        _this.turnoverStatusMetadata = {
            groups: [
                {
                    rows: [
                        {
                            title: '就業状況',
                            fields: [
                                {
                                    type: 'label',
                                    name: 'workStatus',
                                },
                            ],
                        },
                        {
                            title: '調査日',
                            fields: [
                                {
                                    type: 'label',
                                    name: 'workStatusDate',
                                    supplier: function (value) { return _this.datePipe.transform(value, 'yyyy/MM/dd (E)'); },
                                },
                            ],
                        },
                        {
                            title: '調査方法',
                            fields: [
                                {
                                    type: 'label',
                                    name: 'workStatusCheckMethod',
                                },
                            ],
                        },
                    ],
                },
            ],
        };
        _this.truncateAt = DEFAULT_TRUNCATE_AT;
        _this.historyRemarks = '';
        return _this;
    }
    ProgressDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.dynamicService.fieldUpdateEvent.subscribe(function () {
            _this.turnoverStatusMetadataEdit = {
                groups: [
                    {
                        rows: _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('progress', 'workStatus')).concat(_this.dynamicService.getFormRows(_this.dynamicService.getDefinition('progress', 'workStatusDate')), _this.dynamicService.getFormRows(_this.dynamicService.getDefinition('progress', 'workStatusCheckMethod'))),
                    },
                ],
            };
        });
    };
    ProgressDetailComponent.prototype.onHasInterViewChange = function (event) {
        var _this = this;
        event.preventDefault();
        var hasInterviewUpdate = this.progress.hasInterview ? 0 : 1;
        this.progressApiService
            .updateWithoutAction(this.referenceId, {
            type: this.progress.type,
            hasInterview: hasInterviewUpdate,
            n: this.progress.n,
            seminarType: this.progress.seminarType,
            seminarAt: this.progress.seminarAt != null ? new Date(this.progress.seminarAt).toAsialDateTimeString() : null,
            isStudentDelay: this.progress.isStudentDelay,
            dynamicData: this.progress.dynamicData,
        })
            .subscribe(function () {
            _this.progress.hasInterview = hasInterviewUpdate;
            _this.progressApiService.refreshEvent.emit();
        });
    };
    ProgressDetailComponent.prototype.editHistoryRemark = function (remarks) {
        this.isHistoryRemarkEditInProgress = true;
        this.historyRemarkText = remarks;
    };
    ProgressDetailComponent.prototype.cancelHistoryRemarkEdit = function () {
        this.historyRemarkText = '';
        this.isHistoryRemarkEditInProgress = false;
    };
    ProgressDetailComponent.prototype.saveHistoryRemarkText = function (history) {
        var _this = this;
        this.progressApiService.updateHistoryRemarks(this.referenceId, history.revision, { remarks: this.historyRemarkText }).subscribe(function () {
            _this.progressApiService.refreshEvent.emit();
            history.remarks = _this.historyRemarkText;
            _this.cancelHistoryRemarkEdit();
        });
    };
    ProgressDetailComponent.prototype.onRevisionClick = function (revision) {
        var _this = this;
        PopupControlComponent.instance.open({
            title: 'ステータスの戻し',
            content: this.progressReturnPopupTemplate,
            confirmText: '更新',
            confirmEnabled: function () { return (_this.historyRemarks && _this.historyRemarks.trim() ? true : false); },
            confirmCallback: function () {
                _this.progressApiService.revertHistory(_this.referenceId, revision, _this.historyRemarks).subscribe(function (progress) {
                    _this.historyRemarks = '';
                    _this.progressApiService.refreshEvent.emit();
                });
            },
            cancelText: 'キャンセル',
        });
    };
    ProgressDetailComponent.prototype.editStopReasonText = function () {
        this.isStopReasonEditInProgress = true;
        this.stopReasonText = this.progress.dynamicData.stopReason;
    };
    ProgressDetailComponent.prototype.cancelStopReasonTextEdit = function () {
        this.stopReasonText = '';
        this.isStopReasonEditInProgress = false;
    };
    ProgressDetailComponent.prototype.saveStopReasonText = function () {
        var _this = this;
        this.progressApiService
            .updateWithoutAction(this.referenceId, {
            type: this.progress.type,
            hasInterview: this.progress.hasInterview,
            n: this.progress.n,
            seminarType: this.progress.seminarType,
            seminarAt: this.progress.seminarAt != null ? new Date(this.progress.seminarAt).toAsialDateTimeString() : null,
            isStudentDelay: this.progress.isStudentDelay,
            dynamicData: tslib_1.__assign({}, this.progress.dynamicData, { stopReason: this.stopReasonText }),
        })
            .subscribe(function () {
            _this.progress.dynamicData.stopReason = _this.stopReasonText;
            _this.progressApiService.refreshEvent.emit();
            _this.cancelStopReasonTextEdit();
        });
    };
    ProgressDetailComponent.prototype.editTurnOverForm = function () {
        this.turnoverStatusModel = {
            workStatus: this.progress && this.progress.dynamicData && this.progress.dynamicData.workStatus ? this.progress.dynamicData.workStatus : null,
            workStatusDate: this.progress && this.progress.dynamicData && this.progress.dynamicData.workStatusDate
                ? this.progress.dynamicData.workStatusDate
                : null,
            workStatusCheckMethod: this.progress && this.progress.dynamicData && this.progress.dynamicData.workStatusCheckMethod
                ? this.progress.dynamicData.workStatusCheckMethod
                : null,
        };
        this.isTurnOverFormEditInProgress = true;
    };
    ProgressDetailComponent.prototype.cancelTurnOverFormEdit = function () {
        this.turnoverStatusModel = null;
        this.isTurnOverFormEditInProgress = false;
    };
    ProgressDetailComponent.prototype.saveTurnOverForm = function () {
        var _this = this;
        this.progressApiService
            .updateWithoutAction(this.referenceId, {
            type: this.progress.type,
            hasInterview: this.progress.hasInterview,
            n: this.progress.n,
            seminarType: this.progress.seminarType,
            seminarAt: this.progress.seminarAt,
            isStudentDelay: this.progress.isStudentDelay,
            dynamicData: tslib_1.__assign({}, this.progress.dynamicData, this.turnoverStatusModel),
        })
            .subscribe(function () {
            _this.progress.dynamicData = tslib_1.__assign({}, _this.progress.dynamicData, _this.turnoverStatusModel);
            _this.progressApiService.refreshEvent.emit();
            _this.cancelTurnOverFormEdit();
        });
    };
    ProgressDetailComponent.prototype.getProgressStatusDateLabel = function (progress) {
        if (progress.eventAt && progress.eventName) {
            return this.datePipe.transform(progress.eventAt, progress.eventName + ": yyyy/MM/dd(E) HH:mm");
        }
        return '';
    };
    ProgressDetailComponent.prototype.getContactUser = function (userId) {
        return userId != null ? this.userApiService.getAll().pipe(map(function (users) { return users.find(function (user) { return user.id === userId; }); })) : of(null);
    };
    ProgressDetailComponent.prototype.getContactTeamName = function (userId) {
        var _this = this;
        return this.getContactUser(userId).pipe(concatMap(function (user) { return (user ? _this.getTeamNameForUser(user) : of(null)); }));
    };
    ProgressDetailComponent.prototype.getJobContactUser = function (jobUsers, type) {
        var jobUserType = type === 0 ? EnterpriseDepartmentUserType.RA : type === 1 ? EnterpriseDepartmentUserType.PA : null;
        var jobUser = jobUsers && jobUsers.length ? jobUsers.find(function (user) { return user.type === jobUserType; }) : null;
        return jobUser && jobUser.id != null ? this.getContactUser(jobUser.userId) : of(null);
    };
    ProgressDetailComponent.prototype.getJobContactTeamName = function (jobUsers, type) {
        var _this = this;
        return this.getJobContactUser(jobUsers, type).pipe(concatMap(function (user) { return (user ? _this.getTeamNameForUser(user) : of(null)); }));
    };
    ProgressDetailComponent.prototype.getTeamNameForUser = function (user) {
        return this.masterApiService.getTeams().pipe(map(function (teams) {
            var foundTeam = teams.find(function (team) { return team.id === user.teamId; });
            return foundTeam ? foundTeam.name : '';
        }));
    };
    ProgressDetailComponent.prototype.onStudentLinkClick = function (studentId) {
        this.onLinkClick(StudentDetailPageComponent.instance, studentId);
    };
    ProgressDetailComponent.prototype.onCompanyLinkClick = function (companyId) {
        this.onLinkClick(CompanyDetailPageComponent.instance, companyId);
    };
    ProgressDetailComponent.prototype.onJobLinkClick = function (jobId) {
        this.onLinkClick(JobDetailPageComponent.instance, jobId);
    };
    ProgressDetailComponent.prototype.onLinkClick = function (detail, id) {
        detail.referenceId = id;
        detail.open();
    };
    ProgressDetailComponent.prototype.fill = function (onRefresh, event) {
        var _this = this;
        if (event && event === RefreshEvent.DELETE) {
            return;
        }
        this.cancelHistoryRemarkEdit();
        this.cancelStopReasonTextEdit();
        this.cancelTurnOverFormEdit();
        if (this.referenceId) {
            this.updateUrl();
            this.progress = null;
            this.progressApiService
                .getDetail(this.referenceId)
                .pipe(concatMap(function (progress) {
                return forkJoin(_this.studentApiService.getDetail(progress.studentId), _this.companyApiService.getDetail(progress.enterpriseId), _this.jobApiService.getDetail(progress.jobId), of(progress));
            }))
                .subscribe(function (_a) {
                var student = _a[0], company = _a[1], job = _a[2], progress = _a[3];
                _this.progress = tslib_1.__assign({}, progress, { student: student, enterprise: company, job: job });
            });
        }
    };
    return ProgressDetailComponent;
}(DetailPage));
export { ProgressDetailComponent };
