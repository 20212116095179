import * as tslib_1 from "tslib";
import { ApiService } from '@agent-ds/shared/models/api-service';
import { toAsialRequestParameters } from '@agent-ds/shared/util/util';
import { HttpClient } from '@angular/common/http';
import { REPORT_SUCCESS_HEADER } from '../auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ProgressApiService = /** @class */ (function (_super) {
    tslib_1.__extends(ProgressApiService, _super);
    function ProgressApiService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    ProgressApiService.prototype.addProgress = function (request) {
        var _a;
        return this.http.post('/api/progress', request, { headers: (_a = {}, _a[REPORT_SUCCESS_HEADER] = 'TRUE', _a) });
    };
    ProgressApiService.prototype.getList = function (params) {
        return this.http.get('/api/progress/search', {
            params: toAsialRequestParameters(params),
        });
    };
    ProgressApiService.prototype.updateHistoryRemarks = function (progressId, revision, remarks) {
        return this.http.put("/api/progress/" + progressId + "/history/" + revision, remarks);
    };
    ProgressApiService.prototype.updateWithAction = function (progressId, requestObject) {
        return this.http.put("/api/progress/" + progressId, requestObject);
    };
    ProgressApiService.prototype.updateWithoutAction = function (progressId, requestObject) {
        return this.http.put("/api/progress/" + progressId + "/no-action", requestObject);
    };
    ProgressApiService.prototype.delete = function (progressId) {
        return this.http.delete("/api/progress/" + progressId);
    };
    ProgressApiService.prototype.getDetail = function (progressId) {
        return this.http.get("/api/progress/" + progressId);
    };
    ProgressApiService.prototype.revertHistory = function (progressId, revision, remarks) {
        return this.http.put("/api/progress/" + progressId + "/revert/" + revision, null, { params: { remarks: remarks } });
    };
    ProgressApiService.prototype.bulkRegisterStudent = function (studentId, jobIds) {
        return this.http.put("/api/progress/bulk/student/" + studentId + "/register", {
            jobIds: jobIds,
        });
    };
    ProgressApiService.prototype.bulkRegisterStudentsByJobId = function (jobId, studentIds) {
        return this.http.put("/api/progress/bulk/job/" + jobId + "/register", {
            studentIds: studentIds,
        });
    };
    ProgressApiService.prototype.bulkRegisterStudentsByProgressIds = function (progressIds) {
        return this.http.put('/api/progress/bulk/waiting-student-ok/overwrite', {
            progressIds: progressIds,
        });
    };
    ProgressApiService.prototype.bulkRecommendStudent = function (studentId, jobIds) {
        return this.http.put("/api/progress/bulk/student/" + studentId + "/recommend", {
            jobIds: jobIds,
        });
    };
    ProgressApiService.prototype.bulkRegisterStudentNoUpdate = function (studentId, jobIds) {
        return this.http.put("/api/progress/bulk/student/" + studentId + "/register/no-update", {
            jobIds: jobIds,
        });
    };
    ProgressApiService.prototype.bulkDecline = function (progressIds) {
        return this.http.put('/api/progress/bulk/end', { progressIds: progressIds });
    };
    ProgressApiService.prototype.bulkEdit = function (progressIds, request) {
        return this.http.put('/api/progress/bulk/edit', {
            progressIds: progressIds,
            fromStatus: request.fromStatus,
            toStatus: request.toStatus,
            stopReason: request.stopReason,
            remarks: request.remarks,
        });
    };
    ProgressApiService.prototype.bulkSettingInterview = function (request) {
        return this.http.put('/api/progress/bulk/setting-interview', request);
    };
    ProgressApiService.prototype.checkProgress = function (studentIds, jobIds) {
        return this.http.get('/api/progress/check', {
            params: toAsialRequestParameters({ studentIds: studentIds, jobIds: jobIds }),
        });
    };
    ProgressApiService.prototype.getStakeholders = function (progressIds) {
        return this.http.get('/api/progress/stakeholders/email', {
            params: toAsialRequestParameters({ progressIds: progressIds }),
        });
    };
    ProgressApiService.prototype.overwrite = function (progressId, requestObject) {
        return this.http.put("/api/progress/" + progressId + "/overwrite", requestObject);
    };
    ProgressApiService.prototype.getContractInfo = function (progressId) {
        return this.http.get("/api/progress/" + progressId + "/contract-info");
    };
    ProgressApiService.prototype.getReportRapa = function (date, teamId) {
        return this.http.get('/api/progress/monthly-kpi/rapa', { params: tslib_1.__assign({}, date, { teamId: teamId + '' }) });
    };
    ProgressApiService.prototype.getReportCa = function (date, teamId) {
        return this.http.get('/api/progress/monthly-kpi/ca', { params: tslib_1.__assign({}, date, { teamId: teamId + '' }) });
    };
    ProgressApiService.prototype.ngEnd = function (progressIds) {
        return this.http.put('/api/progress/bulk/ng-end', { progressIds: progressIds });
    };
    ProgressApiService.prototype.bulkCreate = function (jobIds, studentIds) {
        return this.http.post('/api/progress/bulk', { jobIds: jobIds, studentIds: studentIds });
    };
    ProgressApiService.prototype.bulkCreateStudent = function (studentId, jobIds, request) {
        return this.http.post("/api/progress/bulk/student/" + studentId, tslib_1.__assign({}, request, { jobIds: jobIds }));
    };
    ProgressApiService.prototype.bulkCreateJob = function (jobId, studentIds, request) {
        return this.http.post("/api/progress/bulk/job/" + jobId, tslib_1.__assign({}, request, { studentIds: studentIds }));
    };
    ProgressApiService.prototype.bulkCreateCombination = function (combinations) {
        return this.http.post("/api/progress/bulk/combination", {
            studentIdJobIds: combinations,
        });
    };
    ProgressApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProgressApiService_Factory() { return new ProgressApiService(i0.ɵɵinject(i1.HttpClient)); }, token: ProgressApiService, providedIn: "root" });
    return ProgressApiService;
}(ApiService));
export { ProgressApiService };
